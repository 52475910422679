import { useCallback, useEffect, useState } from "react";

const MOBILE_SIZE = 767;

export const useIsMobile = () => {
  const checkMobile = useCallback(() => {
    return window.matchMedia(`(max-width: ${MOBILE_SIZE}px)`).matches;
  }, []);

  const [isMobile, setIsMobile] = useState(() => checkMobile());

  useEffect(() => {
    const resizeHandler = () => setIsMobile(checkMobile());

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [checkMobile]);

  return isMobile;
};
