import { useEffect, useRef } from "react";

import { useTexture, Sparkles } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { AdditiveBlending, DoubleSide, Vector3 } from "three";
import { randFloat } from "three/src/math/MathUtils.js";

export const TOKENS_LIST = {
  ethereum: "/animations/tokens/ethereum.png",
  bitcoin: "/animations/tokens/bitcoin.png",
  atom: "/animations/tokens/atom.png",
  polygon: "/animations/tokens/polygon.png",
  evmos: "/animations/tokens/evmos.png",
  neutron: "/animations/tokens/neutron.png",
  juno: "/animations/tokens/juno.png",
  osmosis: "/animations/tokens/osmosis.png",
};

const POSITION = new Vector3(2.9, 0, 0);

const INIT_DISTANCE = 3;

const TOKEN_DISTANCE = 1.5;

const GROUP_HEIGHT =
  Object.keys(TOKENS_LIST).length * TOKEN_DISTANCE + INIT_DISTANCE;

export const Tokens = ({
  isMobile,
  sparklesColor,
  position = POSITION,
  blending,
}) => {
  const textures = useTexture(TOKENS_LIST);

  const tokenRefs = useRef({});

  const groupRef = useRef(null);

  useEffect(() => {
    const group = groupRef.current;

    const tokens = Object.values(tokenRefs.current);

    tokens.forEach((token, index) => {
      const { mesh } = token;

      setTokenPosition(mesh, index, isMobile);

      mesh.position.y = -(index * TOKEN_DISTANCE);

      mesh.visible = true;
    });

    group.position.y = -INIT_DISTANCE;
  }, [isMobile]);

  useFrame((_, delta) => {
    const group = groupRef.current;

    if (!group) return;

    const tokens = Object.values(tokenRefs.current);

    group.position.y += 0.8 * delta;

    tokens.forEach((token) => {
      const { mesh } = token;

      mesh.rotation.y += 0.8 * delta;
    });

    if (group.position.y > GROUP_HEIGHT) {
      group.position.y = -INIT_DISTANCE;

      // random X position
      tokens.forEach((token, idx) => {
        setTokenPosition(token.mesh, idx, isMobile);
      });
    }

    group.visible = true;
  });

  return (
    <group position={position} ref={groupRef} visible={false}>
      {Object.keys(TOKENS_LIST).map((name) => (
        <Token
          key={name}
          name={name}
          texture={textures[name]}
          refs={tokenRefs.current}
          sparklesColor={sparklesColor}
          blending={blending}
        />
      ))}
    </group>
  );
};

const Token = ({ name, texture, sparklesColor, refs, blending }) => {
  return (
    <mesh
      visible={false}
      name={name}
      ref={(ref) => {
        refs[name] = {
          ...refs[name],
          mesh: ref,
        };
      }}
    >
      <planeGeometry args={[0.3, 0.3]} />

      <meshStandardMaterial
        map={texture}
        transparent
        side={DoubleSide}
        metalness={0}
        roughness={1}
        blending={blending ?? AdditiveBlending}
      />

      <Sparkles
        count={30}
        color={sparklesColor ?? "#ffffff"}
        scale={0.03}
        speed={1}
      />
    </mesh>
  );
};

const setTokenPosition = (mesh, idx, isMobile) => {
  if (isMobile) {
    mesh.position.x = idx % 2 === 0 ? -1.3 : 1.3;
    return;
  }

  mesh.position.x = randFloat(-0.5, 0.5);
};
