import React, { useMemo } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AnimateFadeInUp from "./AnimateFadeInUp"; // You need to import the AnimateFadeInUp here
import { ElysHeroRender } from "../animations/ElysHeroRender/ElysHeroRender";
import { useIsMobile } from "../hooks/useIsMobile";
import { APP_LINK /*DISCORD_LINK, TWITTER_LINK*/ } from "../constants/all";
// import { Icons } from "../constants/icons";

const LINKS = [
  {
    name: "Launch Testnet App",
    link: APP_LINK,
  },
  // {
  //   name: "Join Discord",
  //   link: DISCORD_LINK,
  // },
  // {
  //   name: "Join Twitter",
  //   link: TWITTER_LINK,
  // },
];

const HeroSection = () => {
  const isMobile = useIsMobile();

  // get random link
  const currentLink = useMemo(
    () => LINKS[Math.floor(Math.random() * LINKS.length)],
    []
  );

  return (
    <section className="hero-sec bg-black section-bg">
      <VideoComponent /> {/* Make sure to import and use the VideoComponent */}
      <Container>
        <Row>
          <Col lg={7}>
            <div className="hero-sec-text">
              <AnimateFadeInUp showIn={isMobile}>
                <h1>
                  Universal <br /> Liquidity <br /> Layer
                </h1>

                {/* <h2 className="main-subtitle">
                  Fast, Simple Trading <br /> on any Device
                </h2> */}
              </AnimateFadeInUp>

              <AnimateFadeInUp showIn={isMobile}>
                <a
                  href={currentLink.link}
                  target="_blank"
                  className="btn btn-primary btn-lg d-inline-block"
                  rel="noreferrer"
                >
                  {currentLink.name}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.5 15L12.5 10L7.5 5"
                      stroke="black"
                      strokeWidth="2"
                      strokeLinecap="square"
                    />
                  </svg>
                </a>
              </AnimateFadeInUp>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const VideoComponent = () => {
  return (
    <div className="video-bg">
      <ElysHeroRender />
    </div>
  );
};

export default HeroSection;
