import React from "react";
import "animate.css/animate.min.css";
import "bootstrap/dist/css/bootstrap.css";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Navbar from "./app/Navbar";
import Footer from "./app/Footer";
import SuffleTitle from "./landing/SuffleTitle/SuffleTitle";
import UltimateExperience from "./landing/UltimateExperience";
import BringingWorld from "./landing/BringingWorld";
import MarketBased from "./landing/MarketBased";
import MultiToken from "./landing/MultiToken";
import Sustainable from "./landing/Sustainable";
import BuildingFuture from "./landing/BuildingFuture";
import HeroSection from "./landing/HeroSection";
import SimpleFastSection from "./landing/SimpleFastSection";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Navbar />

    <HeroSection />

    <SimpleFastSection />

    <SuffleTitle />

    <UltimateExperience />

    <BringingWorld />

    <MarketBased />

    <MultiToken />

    <Sustainable />

    <BuildingFuture />

    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
