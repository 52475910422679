import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import AnimateFadeInUp from "./AnimateFadeInUp"; // You need to import the AnimateFadeInUp here
import BackToTop from "../app/BackToTop";

const SimpleFastSection = () => {
  return (
    <section className="simple-fast-secure light-bg position-relative">
      <div className="container">
        <AnimateFadeInUp addOnClass="">
          <div className="text-center text-black">
            <h2>Simple. Fast. Secure.</h2>
            <p className="pb-5">DeFi simplified for the Masses</p>
          </div>
        </AnimateFadeInUp>
        <div className="position-relative">
          <Row>
            <Col md={8} className="offset-md-2">
              <AnimateFadeInUp>
                <video
                  className="w-100 mx-auto simple-video"
                  playsInline
                  loop
                  muted
                  autoPlay
                >
                  <source src="../simple.mp4" type="video/mp4" />
                  {/* <source src="../simple.mp4" type="video/mp4;codecs=hvc1"></source> */}
                  {/* <source src="../simple.webm" type="video/webm"></source> */}
                </video>
              </AnimateFadeInUp>
            </Col>
            <Col md={12} lg={4}>
              <AnimateFadeInUp addOnClass="bg-white p-4 simple-text-box one">
                <div className="grid-content">
                  <div>{/* Your SVG or icon */}</div>
                  <p className="mt-0 mb-0">
                    <span>Safely access</span> your account using email and
                    social.
                  </p>
                  <div className="line">
                    <Image src="../Line1.svg" alt="arrow line" />
                  </div>
                </div>
              </AnimateFadeInUp>
              <AnimateFadeInUp addOnClass="bg-white p-4 simple-text-box two">
                <div className="grid-content">
                  <div>{/* Your SVG or icon */}</div>
                  <p className="mt-0 mb-0">
                    <span>Passive yield</span> bearing products on USDC as well
                    as more advanced perpetual trading and LP leveraging.
                  </p>
                  <div className="line">
                    <Image src="../Line3.svg" alt="arrow line" />
                  </div>
                </div>
              </AnimateFadeInUp>
              <AnimateFadeInUp addOnClass="bg-white p-4 simple-text-box three">
                <div className="grid-content">
                  <div>{/* Your SVG or icon */}</div>
                  <p className="mt-0 mb-0">
                    <span>Buy and sell</span> digital assets with credit cards,
                    bank transfers, and more.
                  </p>
                  <div className="line">
                    <Image src="../Line2.svg" alt="arrow line" />
                  </div>
                </div>
              </AnimateFadeInUp>
            </Col>
          </Row>
        </div>
      </div>
      <BackToTop></BackToTop>
    </section>
  );
};

export default SimpleFastSection;
