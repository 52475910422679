import React, { useState, useEffect } from "react";

const BackToTop = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    if (window.pageYOffset > 200) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div
        className={`back-to-top-box ${showBackToTop ? "show" : ""}`}
        onClick={handleScrollToTop}
        id="backToTopBtn"
      >
        <div className="back-to-top-arrow">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1_483)">
              <path
                d="M12 20.25V3.75"
                stroke="black"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.25 10.5L12 3.75L18.75 10.5"
                stroke="black"
                strokeWidth="2.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_483">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default BackToTop;
// import React, { useState, useEffect } from 'react';
// import { Row, Col, Image } from 'react-bootstrap';

// const BackToTop = () => {
//   const [showBackToTop, setShowBackToTop] = useState(false);
//   const [darkBackground, setDarkBackground] = useState(false);

//   const handleScrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth',
//     });
//   };

//   const handleScroll = () => {
//     if (window.pageYOffset > 200) {
//       setShowBackToTop(true);
//     } else {
//       setShowBackToTop(false);
//     }
//   };

//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     const lightBgSections = document.querySelectorAll('.light-bg');

//     // Create an observer instance
//     const observer = new IntersectionObserver(entries => {
//       const isVisible = entries.some(entry => entry.isIntersecting);
//       setDarkBackground(isVisible);
//     });

//     // Observe each light-bg section
//     lightBgSections.forEach(section => observer.observe(section));

//     // Clean up the observer
//     return () => {
//       observer.disconnect();
//     };
//   }, []);

//   return (
//     <div>
//       <div className={`back-to-top-box shadow ${showBackToTop ? 'show' : ''} ${darkBackground ? 'dark-bg' : ''}`}
//         onClick={handleScrollToTop} id='backToTopBtn'>
//         <div className="back-to-top-arrow">
//         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
// // <g clipPath="url(#clip0_1_483)">
// // <path d="M12 20.25V3.75" stroke="black" strokeWidth="2.5" strokeLinecap="round" stroke-linejoin="round"/>
// // <path d="M5.25 10.5L12 3.75L18.75 10.5" stroke="black" strokeWidth="2.5" strokeLinecap="round" stroke-linejoin="round"/>
// // </g>
// // <defs>
// // <clipPath id="clip0_1_483">
// // <rect width="24" height="24" fill="white"/>
// // </clipPath>
// // </defs>
// // </svg>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BackToTop;
