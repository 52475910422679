import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import AnimateFadeInUp from "../AnimateFadeInUp";
import BackToTop from "../../app/BackToTop";

function SuffleTile() {
  useEffect(() => {
    const cards = document.querySelectorAll(".card");
    const container = document.querySelector(".discover-box");
    container.addEventListener("click", handleCardClick);

    let activeCardIndex = 0;

    function swapCards() {
      const nextCardIndex = (activeCardIndex + 1) % cards.length;
      const activeCard = cards[activeCardIndex];
      const nextCard = cards[nextCardIndex];

      let activeClassList = activeCard.classList.toString();
      let nextClassList = nextCard.classList.toString();

      activeCard.className = nextClassList;
      nextCard.className = activeClassList;

      activeCardIndex = nextCardIndex;
    }

    function handleCardClick(event) {
      const clickedCard = event.target.closest(".card");
      const clickedCardIndex = Array.from(cards).indexOf(clickedCard);

      if (clickedCardIndex !== -1 && clickedCardIndex !== activeCardIndex) {
        const activeCard = cards[activeCardIndex];
        const clickedCard = cards[clickedCardIndex];

        let activeCardList = activeCard.classList.toString();
        let clickedCardList = clickedCard.classList.toString();

        activeCard.className = clickedCardList;
        clickedCard.className = activeCardList;

        activeCardIndex = clickedCardIndex;
      }
    }

    const interval = setInterval(swapCards, 5000);
    return () => {
      clearInterval(interval);
      container.removeEventListener("click", handleCardClick);
    };
  }, []);

  return (
    <section className="discover-elys position-relative">
      <div className="container">
        <AnimateFadeInUp>
          <div className="text-center text-white">
            <h2 className="mb-0 mb-md-5 pb-0 pb-md-3">
              Discover the Elys <br className="d-none d-md-inline-block"></br>
              Network Difference
            </h2>
          </div>
        </AnimateFadeInUp>
        <AnimateFadeInUp afterFadeClass={"remove-fade-css"}>
          <div className="discover-box">
            <Image src="iphone.png"></Image>
            <Image src="../logo.svg" className="logo"></Image>
            <a href="#footer" className="discover-box-btn">
              <span className="arrow">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.5 15L12.5 10L7.5 5"
                    stroke="black"
                    strokeWidth="2"
                    strokeLinecap="square"
                  ></path>
                </svg>
              </span>
              DeFi for the Masses
            </a>

            <div className="card discover-box-content center-top active">
              <Image src="center-top-icon.png"></Image>
              <div>Non-inflationary system rewards, paid in USDC</div>
            </div>
            <div className="card discover-box-content left-top">
              <Image src="left-top-icon.png"></Image>
              <div>Hybrid DEX with AMM & unique oracle-based model</div>
            </div>

            <div className="card discover-box-content left-bottom">
              <Image src="center-bottom-icon.png"></Image>
              <div>Margin trading with integrated oracle pricing</div>
            </div>
            {/* <div className='card discover-box-content center-bottom'>
                        <Image src="left-bottom-icon.png"></Image>
                        <div>
                            AI integration for premier user experience
                        </div>
                    </div> */}
            <div className="card discover-box-content right-top">
              <Image src="right-top-icon.png"></Image>
              <div>
                USDC lending to facilitate leverage for liquidity providers
              </div>
            </div>
            <div className="card discover-box-content right-bottom">
              <Image src="right-bottom-icon.png"></Image>
              <div>
                ETF style Index pools for risk management and diversification
              </div>
            </div>
          </div>
        </AnimateFadeInUp>
      </div>
      <BackToTop></BackToTop>
    </section>
  );
}

export default SuffleTile;
