import React from "react";
import { useInView } from "react-intersection-observer";

const AnimateFadeInUp = ({ showIn, children, addOnClass, afterFadeClass }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  const isInView = showIn || inView;

  return (
    <div
      ref={ref}
      className={`${addOnClass} my-element animate__animated ${
        isInView ? `animate__fadeInUp ${afterFadeClass}` : ""
      }`}
    >
      {children}
    </div>
  );
};

export default AnimateFadeInUp;
