"use client";

import { useMemo, useRef } from "react";

import { PointMaterial, Points } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useControls } from "leva";
import { Color, MathUtils, Object3D, Vector3 } from "three";
import { degToRad } from "three/src/math/MathUtils.js";

const STARS_COUNT = 7000;

const STARS_DISTANCE = 512;

const _OBJECT = new Object3D();

const _VECTOR = new Vector3();

const STARS_COLOR = new Color("#FFFFFF");

const STARS_SIZE = 0.45;

const STARS_OPACITY = 1;

const STARS_SPEED = 30;

export const Stars = ({
  count = STARS_COUNT,
  distance = STARS_DISTANCE,
  color = STARS_COLOR,
  size = STARS_SIZE,
}) => {
  const starsPosition = new Vector3(0, 0, -(distance / 2) + 25);

  const pointsRef = useRef(null);

  const starsBuffer = useMemo(() => {
    const starsBuffer = new Float32Array(count * 3);

    for (let i = 0; i < count; i++) {
      const i3 = i * 3;

      _VECTOR.x = MathUtils.randFloatSpread(distance);
      _VECTOR.y = MathUtils.randFloatSpread(distance / 2);
      _VECTOR.z = MathUtils.randFloatSpread(distance);

      starsBuffer[i3] = _VECTOR.x;
      starsBuffer[i3 + 1] = _VECTOR.y;
      starsBuffer[i3 + 2] = _VECTOR.z;
    }

    return starsBuffer;
  }, [count, distance]);

  useFrame((_, delta) => {
    const points = pointsRef.current;
    if (!points) return;

    const material = points.material;

    material.opacity = Math.min(material.opacity + delta * 0.5, STARS_OPACITY);

    const positions = points.geometry.attributes.position;

    for (let i = 0; i < positions.count; i++) {
      _OBJECT.position.fromBufferAttribute(positions, i);
      _OBJECT.translateZ(delta * STARS_SPEED);

      const diff = starsPosition.distanceTo(_OBJECT.position);

      if (diff > distance) {
        _OBJECT.translateZ(-distance);
      }

      positions.setXYZ(
        i,
        _OBJECT.position.x,
        _OBJECT.position.y,
        _OBJECT.position.z
      );
    }
  });

  return (
    <Points
      key={starsBuffer.length}
      ref={pointsRef}
      position={starsPosition}
      rotation={[0, degToRad(-15), degToRad(-15)]}
      positions={starsBuffer}
      stride={3}
      frustumCulled={false}
    >
      <PointMaterial
        transparent
        opacity={0}
        color={color}
        size={size}
        sizeAttenuation={true}
      />
    </Points>
  );
};

export const DebugStars = () => {
  useControls("Stars", {
    StarsCount: {
      step: 100,
      value: STARS_COUNT,
      min: 1000,
      max: 50000,
    },

    StarsColor: "#FFFFFF",

    StarsSize: {
      step: 0.01,
      value: STARS_SIZE,
      min: 0.1,
      max: 1,
    },

    StarsOpacity: {
      step: 0.01,
      value: STARS_OPACITY,
      min: 0.1,
      max: 1,
    },

    StarsSpeed: {
      step: 1,
      value: STARS_SPEED,
      min: 1,
      max: 200,
    },
  });
};
