export const createPointCloud = (img, maxPoints, size = 1) => {
  const scale = size / img.width;

  const widthScale = scale;
  const heightScale = scale;

  const canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext("2d");

  if (!ctx) return null;

  ctx.drawImage(img, 0, 0, img.width, img.height);

  const imageData = ctx.getImageData(0, 0, img.width, img.height);

  const positions = new Float32Array(maxPoints * 3);

  let step = Math.sqrt((img.width * img.height) / maxPoints);
  step = Math.max(step, 1);

  let currentPoint = 0;

  for (let x = 0; x < img.height; x += step) {
    for (let y = 0; y < img.width; y += step) {
      const index = (Math.floor(x) * img.width + Math.floor(y)) * 4;
      const a = imageData.data[index + 3] / 255;

      // Set an appropriate threshold value
      if (a > 0) {
        const stride = currentPoint * 3;

        const xPos = (y - img.width / 2) * widthScale;
        const yPos = (img.height / 2 - x) * heightScale;

        positions[stride] = xPos;
        positions[stride + 1] = yPos;
        positions[stride + 2] = 0;

        currentPoint++;
      }
    }
  }

  return positions.slice(0, currentPoint * 3);
};

export const getPixelRatio = () => {
  if (typeof window === "undefined" || !window.devicePixelRatio) return [1, 2];

  return window.devicePixelRatio;
};
