import { useEffect, useMemo } from "react";

import { useThree } from "@react-three/fiber";
import { useControls } from "leva";
import { ACESFilmicToneMapping, Color } from "three";

const BACKGROUND_COLOR = new Color("#000000");

const DEFAULT_CAMERA_FOV = 65;

export const Environment = ({
  backgroundColor: backgroundColorProp,
  debug,
}) => {
  const scene = useThree((state) => state.scene);
  const gl = useThree((state) => state.gl);

  const backgroundColor = useMemo(
    () => backgroundColorProp ?? BACKGROUND_COLOR,
    [backgroundColorProp]
  );

  scene.background = backgroundColor;

  gl.toneMapping = ACESFilmicToneMapping;

  const camera = useThree((state) => state.camera);

  const size = useThree((state) => state.size);

  useEffect(() => {
    // set aspect
    const aspect = size.width / size.height;
    camera.aspect = aspect;

    const fov =
      (Math.atan(
        Math.tan(((DEFAULT_CAMERA_FOV / 2) * Math.PI) / 180) / aspect
      ) *
        2 *
        180) /
      Math.PI;

    camera.fov = fov;

    camera.updateProjectionMatrix();
  }, [camera, size]);

  return (
    <>
      <ambientLight intensity={1} color="#FFFFFF" />

      <directionalLight color="#FFFFFF" position={[0, 0, 5]} intensity={1} />

      {/* <directionalLight color="#FFFFFF" position={[0, 0, -5]} intensity={1} /> */}

      {/* <pointLight position={[0, 1, 5]} args={['#FFFFFF', 1, 300, 0]} /> */}

      {debug && <BackgroundDebug backgroundColor={backgroundColor} />}
    </>
  );
};

const BackgroundDebug = ({ backgroundColor }) => {
  useControls({
    BackgroundColor: {
      value: `#${backgroundColor.getHexString()}`,
      onChange: (value) => {
        backgroundColor.set(value);
      },
    },
  });

  return null;
};
