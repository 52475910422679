import {useFrame, useThree} from '@react-three/fiber';
import {Vector3} from 'three';

const _VECTOR = new Vector3();

export const Cursor = () => {
  const {camera, mouse} = useThree();

  useFrame(() =>
    camera.position.lerp(
      _VECTOR.set(mouse.x * 1, mouse.y * 1, camera.position.z),
      0.01,
    ),
  );

  return null;
};
