export const CONTACT_EMAIL = "team@elys.network";

export const APP_LINK = "https://testnet.elys.network";

export const WAITLIST_LINK = "https://tally.so/r/mVQBev";

export const TWITTER_LINK = "https://twitter.com/elys_network";

export const LINKTHREE_LINK = "https://linktr.ee/elysnetwork";
export const DISCORD_LINK = "https://discord.gg/elysnetwork";

export const DOCS_LINK = "https://elys-network.gitbook.io";

export const GITHUB_LINK = "https://github.com/elys-network";

export const TELEGRAM_LINK = "https://t.me/ElysNetwork";

export const BRAND_ASSETS_LINK =
  "https://github.com/elys-network/elys-brand-assets";

export const GOVERNANCE_LINK = "https://testnet.elys.network/elys/gov";

export const YOUTUBE_LINK = "https://youtube.com/@elys-network";

export const BLOG_LINK = "https://elysnetwork.medium.com/";
