import React from "react";
import { Container, Row, Col } from "react-bootstrap"; // Assuming you are using react-bootstrap for layout and grid system.
import BackToTop from "./BackToTop";
import {
  APP_LINK,
  BLOG_LINK,
  BRAND_ASSETS_LINK,
  CONTACT_EMAIL,
  DISCORD_LINK,
  DOCS_LINK,
  GITHUB_LINK,
  TELEGRAM_LINK,
  TWITTER_LINK,
  YOUTUBE_LINK,
} from "../constants/all";

const Footer = () => {
  return (
    <footer className="light-bg position-relative" id="footer">
      <Container>
        <Row>
          <Col lg={6} md={3}>
            <div className="mb-3">
              <img src="../logo-dark.svg" alt="Elys Logo"></img>
            </div>
            <p className="footer-para-text">
              Elys is a fast layer 1 blockchain built in the Cosmos ecosystem
              supporting a decentralized suite of financial applications
            </p>

            <div className="social-link mb-4 mb-md-0">
              {/* discord */}
              <a href={DISCORD_LINK} target="_blank" rel="noreferrer">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5535 5.0373C14.5178 4.55271 13.4104 4.20053 12.2526 4C12.1104 4.25707 11.9443 4.60284 11.8298 4.8779C10.599 4.69281 9.37955 4.69281 8.17145 4.8779C8.05693 4.60284 7.88702 4.25707 7.74358 4C6.58455 4.20053 5.47583 4.554 4.44013 5.03985C2.3511 8.19665 1.78479 11.275 2.06794 14.3097C3.4535 15.3444 4.79628 15.973 6.11637 16.3843C6.44233 15.9357 6.73303 15.4588 6.98345 14.9563C6.5065 14.775 6.04967 14.5514 5.61805 14.2917C5.73255 14.2069 5.84457 14.1182 5.95278 14.0269C8.58547 15.2583 11.4459 15.2583 14.0471 14.0269C14.1566 14.1182 14.2686 14.2069 14.3819 14.2917C13.949 14.5526 13.4909 14.7763 13.014 14.9575C13.2644 15.4588 13.5539 15.937 13.8811 16.3855C15.2024 15.9742 16.5464 15.3457 17.932 14.3097C18.2642 10.7917 17.3645 7.74165 15.5535 5.0373ZM7.34212 12.4434C6.55183 12.4434 5.9037 11.7056 5.9037 10.8072C5.9037 9.90872 6.53797 9.16965 7.34212 9.16965C8.14628 9.16965 8.79438 9.90742 8.78053 10.8072C8.78177 11.7056 8.14628 12.4434 7.34212 12.4434ZM12.6578 12.4434C11.8675 12.4434 11.2194 11.7056 11.2194 10.8072C11.2194 9.90872 11.8537 9.16965 12.6578 9.16965C13.462 9.16965 14.1101 9.90742 14.0962 10.8072C14.0962 11.7056 13.462 12.4434 12.6578 12.4434Z"
                    fill="black"
                  />
                </svg>
              </a>

              {/* twitter */}
              <a href={TWITTER_LINK} target="_blank" rel="noreferrer">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.76891 7.01733L9.8036 7.58923L9.22555 7.5192C7.12147 7.25076 5.28329 6.34039 3.72257 4.81143L2.95955 4.05279L2.76302 4.61301C2.34683 5.86186 2.61273 7.18073 3.47979 8.06776C3.94223 8.55796 3.83818 8.62799 3.04048 8.3362C2.76302 8.24283 2.52024 8.1728 2.49712 8.20782C2.41619 8.28952 2.69365 9.35162 2.91331 9.77179C3.21389 10.3554 3.82662 10.9273 4.49715 11.2657L5.06363 11.5342L4.3931 11.5458C3.74569 11.5458 3.72257 11.5575 3.79194 11.8026C4.02315 12.5613 4.93646 13.3666 5.95382 13.7167L6.6706 13.9618L6.04631 14.3353C5.12144 14.8722 4.03472 15.1757 2.94799 15.199C2.42775 15.2107 2 15.2574 2 15.2924C2 15.4091 3.41043 16.0627 4.23125 16.3195C6.69372 17.0781 9.61862 16.7513 11.8152 15.4558C13.3759 14.5337 14.9366 12.7013 15.665 10.9273C16.058 9.98188 16.4511 8.2545 16.4511 7.42583C16.4511 6.88895 16.4858 6.81892 17.1332 6.17699C17.5147 5.8035 17.8731 5.395 17.9425 5.27829C18.0581 5.05653 18.0465 5.05653 17.4569 5.25494C16.4742 5.60509 16.3355 5.5584 16.8211 5.03319C17.1794 4.6597 17.6072 3.98276 17.6072 3.78434C17.6072 3.74933 17.4338 3.80769 17.2372 3.91273C17.0291 4.02944 16.5667 4.20451 16.2199 4.30956L15.5956 4.50797L15.0291 4.12281C14.717 3.91273 14.2777 3.6793 14.0464 3.60927C13.4568 3.44587 12.5551 3.46921 12.0233 3.65596C10.5782 4.18117 9.66487 5.53506 9.76891 7.01733Z"
                    fill="black"
                  />
                </svg>
              </a>

              {/* telegram */}
              <a href={TELEGRAM_LINK} target="_blank" rel="noreferrer">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.0001 1.67188C5.40008 1.67188 1.66675 5.40521 1.66675 10.0052C1.66675 14.6052 5.40008 18.3385 10.0001 18.3385C14.6001 18.3385 18.3334 14.6052 18.3334 10.0052C18.3334 5.40521 14.6001 1.67188 10.0001 1.67188ZM13.8667 7.33854C13.7417 8.65521 13.2001 11.8552 12.9251 13.3302C12.8084 13.9552 12.5751 14.1635 12.3584 14.1885C11.8751 14.2302 11.5084 13.8719 11.0417 13.5635C10.3084 13.0802 9.89175 12.7802 9.18341 12.3135C8.35841 11.7719 8.89175 11.4719 9.36675 10.9885C9.49175 10.8635 11.6251 8.92187 11.6667 8.74687C11.6725 8.72037 11.6718 8.69285 11.6645 8.66671C11.6572 8.64057 11.6437 8.6166 11.6251 8.59687C11.5751 8.55521 11.5084 8.57187 11.4501 8.58021C11.3751 8.59687 10.2084 9.37187 7.93341 10.9052C7.60008 11.1302 7.30008 11.2469 7.03341 11.2385C6.73341 11.2302 6.16675 11.0719 5.74175 10.9302C5.21675 10.7635 4.80841 10.6719 4.84175 10.3802C4.85841 10.2302 5.06675 10.0802 5.45841 9.92187C7.89175 8.86354 9.50841 8.16354 10.3167 7.83021C12.6334 6.86354 13.1084 6.69687 13.4251 6.69687C13.4917 6.69687 13.6501 6.71354 13.7501 6.79687C13.8334 6.86354 13.8584 6.95521 13.8667 7.02187C13.8584 7.07187 13.8751 7.22187 13.8667 7.33854Z"
                    fill="black"
                  />
                </svg>
              </a>

              {/* youtube */}
              <a href={YOUTUBE_LINK} target="_blank" rel="noreferrer">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.2512 4.84333C16.9397 5.03225 17.4819 5.58888 17.6659 6.29576C18.0002 7.5769 18.0002 10.25 18.0002 10.25C18.0002 10.25 18.0002 12.923 17.6659 14.2042C17.4819 14.9111 16.9397 15.4678 16.2512 15.6568C15.0036 16 10.0002 16 10.0002 16C10.0002 16 4.99688 16 3.74915 15.6568C3.0607 15.4678 2.51852 14.9111 2.33452 14.2042C2.00024 12.923 2.00024 10.25 2.00024 10.25C2.00024 10.25 2.00024 7.5769 2.33452 6.29576C2.51852 5.58888 3.0607 5.03225 3.74915 4.84333C4.99688 4.5 10.0002 4.5 10.0002 4.5C10.0002 4.5 15.0036 4.5 16.2512 4.84333ZM8.50026 7.99995V13L12.5003 10.5L8.50026 7.99995Z"
                    fill="black"
                  />
                </svg>
              </a>

              {/* reddit */}
              {/* <a href="/404" target="_blank" rel="noreferrer">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.95843 10.8667C8.95843 10.3917 8.56676 10 8.09176 10C7.61676 10 7.2251 10.3917 7.2251 10.8667C7.2251 11.0965 7.31641 11.317 7.47894 11.4795C7.64147 11.642 7.86191 11.7333 8.09176 11.7333C8.32162 11.7333 8.54206 11.642 8.70459 11.4795C8.86712 11.317 8.95843 11.0965 8.95843 10.8667ZM11.7418 12.8417C11.3668 13.2167 10.5668 13.35 10.0001 13.35C9.43343 13.35 8.63343 13.2167 8.25843 12.8417C8.23816 12.82 8.21364 12.8027 8.18641 12.7908C8.15917 12.779 8.12979 12.7729 8.1001 12.7729C8.0704 12.7729 8.04102 12.779 8.01379 12.7908C7.98655 12.8027 7.96203 12.82 7.94176 12.8417C7.92006 12.8619 7.90276 12.8865 7.89093 12.9137C7.8791 12.9409 7.873 12.9703 7.873 13C7.873 13.0297 7.8791 13.0591 7.89093 13.0863C7.90276 13.1135 7.92006 13.1381 7.94176 13.1583C8.53343 13.75 9.66676 13.8 10.0001 13.8C10.3334 13.8 11.4668 13.75 12.0584 13.1583C12.0801 13.1381 12.0974 13.1135 12.1093 13.0863C12.1211 13.0591 12.1272 13.0297 12.1272 13C12.1272 12.9703 12.1211 12.9409 12.1093 12.9137C12.0974 12.8865 12.0801 12.8619 12.0584 12.8417C11.9751 12.7583 11.8334 12.7583 11.7418 12.8417ZM11.9084 10C11.4334 10 11.0418 10.3917 11.0418 10.8667C11.0418 11.3417 11.4334 11.7333 11.9084 11.7333C12.3834 11.7333 12.7751 11.3417 12.7751 10.8667C12.7751 10.3917 12.3918 10 11.9084 10Z"
                    fill="black"
                  />
                  <path
                    d="M10.0001 1.67188C5.40008 1.67188 1.66675 5.40521 1.66675 10.0052C1.66675 14.6052 5.40008 18.3385 10.0001 18.3385C14.6001 18.3385 18.3334 14.6052 18.3334 10.0052C18.3334 5.40521 14.6001 1.67188 10.0001 1.67188ZM14.8334 11.1135C14.8501 11.2302 14.8584 11.3552 14.8584 11.4802C14.8584 13.3469 12.6834 14.8635 10.0001 14.8635C7.31675 14.8635 5.14175 13.3469 5.14175 11.4802C5.14175 11.3552 5.15008 11.2302 5.16675 11.1135C4.74175 10.9219 4.45008 10.4969 4.45008 10.0052C4.44885 9.76624 4.51825 9.53224 4.64957 9.3326C4.7809 9.13295 4.96829 8.97657 5.18821 8.88308C5.40813 8.78959 5.65077 8.76317 5.88566 8.80714C6.12054 8.85111 6.3372 8.9635 6.50841 9.13021C7.35008 8.52187 8.51675 8.13854 9.80841 8.09687L10.4251 5.18854C10.4334 5.13021 10.4667 5.08021 10.5167 5.05521C10.5667 5.02187 10.6251 5.01354 10.6834 5.02187L12.7001 5.45521C12.7685 5.31652 12.8727 5.19866 13.002 5.11386C13.1313 5.02905 13.281 4.98038 13.4354 4.97289C13.5899 4.96541 13.7435 4.99939 13.8804 5.0713C14.0173 5.14321 14.1325 5.25044 14.2139 5.38186C14.2954 5.51329 14.3403 5.66412 14.3438 5.81871C14.3473 5.9733 14.3095 6.12602 14.2341 6.26104C14.1587 6.39605 14.0486 6.50844 13.9151 6.58654C13.7816 6.66464 13.6297 6.70561 13.4751 6.70521C13.0084 6.70521 12.6334 6.33854 12.6084 5.88021L10.8001 5.49687L10.2501 8.09687C11.5251 8.13854 12.6667 8.53021 13.5001 9.13021C13.6278 9.00822 13.7807 8.91561 13.948 8.85887C14.1152 8.80213 14.2929 8.78263 14.4685 8.80172C14.6441 8.82081 14.8134 8.87804 14.9646 8.9694C15.1158 9.06076 15.2452 9.18405 15.3437 9.33064C15.4422 9.47724 15.5076 9.64359 15.5351 9.81807C15.5627 9.99254 15.5517 10.1709 15.5031 10.3408C15.4545 10.5106 15.3694 10.6677 15.2537 10.8012C15.138 10.9347 14.9946 11.0413 14.8334 11.1135Z"
                    fill="black"
                  />
                </svg>
              </a> */}
            </div>
          </Col>
          <Col lg={2} md={3} className="footer-link">
            <h6>Explore</h6>
            <div>
              <a href="#mission">Mission</a>
              {/* <a href="/404" target="_blank">
                Careers
              </a> */}
              <a href={BLOG_LINK} target="_blank" rel="noreferrer">
                Blog
              </a>
              {/* <a href="#footer">FAQ’s</a> */}
              <a href={BRAND_ASSETS_LINK} target="_blank" rel="noreferrer">
                Brand Assets
              </a>
              <a
                href={`mailto:${CONTACT_EMAIL}`}
                target="_blank"
                rel="noreferrer"
              >
                Contact
              </a>
            </div>
          </Col>
          <Col lg={2} md={3} className="footer-link">
            <h6>Governance</h6>
            <div>
              <a href={APP_LINK}>Dashboard</a>
              {/* <a href="/404">Commonwealth</a> */}
            </div>
          </Col>
          <Col lg={2} md={3} className="footer-link">
            <h6>Documentation</h6>
            <div>
              <a href={DOCS_LINK} target="_blank" rel="noreferrer">
                User Docs
              </a>
              <a href={GITHUB_LINK} target="_blank" rel="noreferrer">
                Github
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="">
            <div className="footer-hr"></div>
          </Col>
          <Col md={6}>
            <p className="footer-para-text">
              © 2023 Elys Network, All Rights Reserved.
            </p>
          </Col>
          {/* <Col md={6} className="text-left text-md-right policy-link">
            <a href="/404">Terms of Use</a>
            <a href="/404">Privacy Policy</a>
          </Col> */}
        </Row>
      </Container>
      <BackToTop></BackToTop>
    </footer>
  );
};

export default Footer;
