import React from "react";
import { useLottie } from "lottie-react";

export function LottoIcon(props) {
  const options = {
    animationData: require(`../../public/lottie/${props.type}.json`),
    loop: true,
  };

  const { View } = useLottie(options);
  return <div style={{ width: "100%", height: "100%" }}>{View}</div>;
}
