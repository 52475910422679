import { useEffect, useState } from "react";

export const AfterEffect = ({ children }) => {
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);
  }, []);

  if (!isReady) return null;

  return <>{children}</>;
};
