import { Fragment, Suspense, useCallback, useEffect, useState } from "react";

import { useTexture } from "@react-three/drei";

import { CloudPointsLogo } from "../components/CloudPointsLogo";
import { Cursor } from "../components/Cursor";
import { Environment } from "../components/Environment";
import { Logo } from "../components/Logo";
import { Stars } from "../components/Stars";
import { TOKENS_LIST, Tokens } from "../components/Tokens";
import { Vector3 } from "three";
import { degToRad } from "three/src/math/MathUtils";

//import { button, useControls } from "leva";

const DESKTOP_CLOUD_POSITION = new Vector3(1.2, 0, 0);

const MOBILE_CLOUD_POSITION = new Vector3(0, 0, 0);

const MOBILE_CLOUD_ROTATION = [0, 0, 0];

const DESKTOP_CLOUD_ROTATION = [0, degToRad(-15), 0];

const MOBILE_TOKENS_POSITION = new Vector3(0, 0, 0);

const DESKTOP_TOKENS_POSITION = new Vector3(2.9, 0, 0);

const MOBILE_LOGO_POSITION = new Vector3(0, 0, 0);

export const Scene = ({ isMobile }) => {
  const [isRenderCloudPoints, setIsRenderCloudPoints] = useState(false);

  const [isRendeLogo, setIsRenderLogo] = useState(false);

  const [isRenderTokens, setIsRenderTokens] = useState(false);

  //const [version, setVersion] = useState(0);

  /* useControls({
    Repeat: button(() => {
      setIsRenderLogo(false);
      setIsRenderTokens(false);
      setVersion((v) => v + 1);
    }),
  }); */

  const handleCloudPointsLogoFinish = useCallback(() => {
    setIsRenderLogo(true);
  }, []);

  const handleLogoFinish = useCallback(() => {
    setIsRenderTokens(true);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsRenderCloudPoints(true);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Fragment>
      <Environment />

      <Suspense fallback={null}>
        <Stars />
      </Suspense>

      {isRenderCloudPoints && (
        <Suspense fallback={null}>
          <CloudPointsLogo
            onFinish={handleCloudPointsLogoFinish}
            position={isMobile ? MOBILE_CLOUD_POSITION : DESKTOP_CLOUD_POSITION}
            rotation={isMobile ? MOBILE_CLOUD_ROTATION : DESKTOP_CLOUD_ROTATION}
            size={7}
          />
        </Suspense>
      )}

      <Logo
        render={isRendeLogo}
        onFinish={handleLogoFinish}
        position={isMobile ? MOBILE_LOGO_POSITION : DESKTOP_CLOUD_POSITION}
      />

      {isRendeLogo && <Cursor />}

      {isRenderTokens && (
        <Suspense fallback={null}>
          <Tokens
            position={
              isMobile ? MOBILE_TOKENS_POSITION : DESKTOP_TOKENS_POSITION
            }
            isMobile={isMobile}
          />
        </Suspense>
      )}
    </Fragment>
  );
};

useTexture.preload("/animations/logos/logo-gradient.png");
useTexture.preload("/animations/misc/env4.jpg");

Object.values(TOKENS_LIST).forEach((token) => useTexture.preload(token));
