import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import AnimateFadeInUp from "./AnimateFadeInUp";
import BackToTop from "../app/BackToTop";

const BuildingFuture = () => {
  return (
    <section
      className="building-future light-bg position-relative"
      id="mission"
    >
      <div className="container">
        <div className="market-based-header">
          <AnimateFadeInUp>
            <h2>Building for a Brighter Future</h2>
            <p className="mt-3 mb-4 text-black pb-2">
              Dedicated to collaboration and transparency to increase trust and
              confidence.
            </p>
          </AnimateFadeInUp>
        </div>
        <Row className="justify-content-md-center mt-5 m-0 pt-3">
          <Col md={6} className="mb-4 mb-md-0">
            <AnimateFadeInUp>
              <div className="building-future-box text-center core-value">
                <h3>Our Core Values</h3>
                <p className="px-4 pt-4 mx-auto">
                  Accountable, Decentralized and Collaborative, Trust and
                  Transparency, High Quality, Safe and Secure, Forward-looking,
                  Simple and Easy
                </p>
                <Image src="../BuildingFuture.png" className="w-100"></Image>
              </div>
            </AnimateFadeInUp>
          </Col>

          <Col md={6} className="mb-4 mb-md-0">
            <AnimateFadeInUp>
              <div className="building-future-box text-center">
                <h3>Our Mission Statement</h3>
                <p className="px-4 pt-4 mx-auto">
                  To create a self-sustaining, open sourced, decentralized suite
                  of applications and tools that allow anyone to access
                  financial services
                </p>
                <Image src="../BuildingFuture2.png" className="w-100"></Image>
              </div>
            </AnimateFadeInUp>
          </Col>
        </Row>
      </div>
      <BackToTop></BackToTop>
    </section>
  );
};

export default BuildingFuture;
