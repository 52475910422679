import { memo, useEffect, useRef, useState } from "react";

import { easings } from "@react-spring/three";
import { useTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import {
  AdditiveBlending,
  BufferAttribute,
  BufferGeometry,
  Color,
  Vector3,
} from "three";
import { degToRad, randFloat, randInt } from "three/src/math/MathUtils.js";
import { createPointCloud } from "../../utils/3d.utils";

const MAX_POINTS = 10000;

const LOGO_SIZE = 2.5;

const LOGO_WIDTH = 0.7104 * LOGO_SIZE;

const DOTS_GEOMETRY = new BufferGeometry();

const _VECTOR = new Vector3();

const POINTS_COLOR = new Color("#FFFFFF");

const POSITION = [1.2, 0, 0];

const ROTATION = [0, degToRad(-15), 0];

const DOTS_SIZE = 10;

export const CloudPointsLogo = memo(
  ({
    onFinish,
    loop,
    position = POSITION,
    rotation = ROTATION,
    color = POINTS_COLOR,
    blending = AdditiveBlending,
    fadeOut = true,
    opacity = 1,
    size = DOTS_SIZE,
  }) => {
    const { logoOutlineTexture, dotTexture } = useTexture({
      logoOutlineTexture: "/animations/logos/logo-white-outline.png",
      dotTexture: "/animations/misc/dot.png",
    });

    const pointsRef = useRef(null);

    const [geometry, setGeometry] = useState(null);

    const [shapePosition, setShapePosition] = useState([]);
    const [originPositions, setOriginPositions] = useState([]);

    useEffect(() => {
      const positionsBuffer = createPointCloud(
        logoOutlineTexture.source.data,
        MAX_POINTS,
        LOGO_WIDTH
      );
      const shapePosition = [];
      const originPositions = [];

      const sizes = new Float32Array(positionsBuffer.length / 3);

      for (let i = 0; i < positionsBuffer.length; i += 3) {
        const noise = randFloat(0.05, 0.1);
        shapePosition.push(
          new Vector3(
            positionsBuffer[i] + noise,
            positionsBuffer[i + 1] + noise,
            positionsBuffer[i + 2] + noise
          )
        );

        originPositions.push(new Vector3());
      }

      // randomize the size of the dots
      for (let i = 0; i < positionsBuffer.length / 3; i++) {
        sizes[i] = !randInt(0, size)
          ? randFloat(1, size)
          : randFloat(0.05, size - 1);
      }

      DOTS_GEOMETRY.setAttribute(
        "position",
        new BufferAttribute(positionsBuffer, 3)
      );
      DOTS_GEOMETRY.setAttribute("size", new BufferAttribute(sizes, 1));

      setShapePosition(shapePosition);
      setOriginPositions(originPositions);
      setGeometry(DOTS_GEOMETRY);
    }, [size, logoOutlineTexture]);

    const stepRef = useRef(0);

    const isHidingRef = useRef(false);
    const isFirstTimeRef = useRef(true);

    isHidingRef.current = false;
    stepRef.current = 0;

    useFrame((_, delta) => {
      const points = pointsRef.current;
      if (!points?.visible) return;

      // start hiding
      if (stepRef.current === 0) {
        // in
        originPositions.forEach((position, i) => {
          position.copy(shapePosition[i]);

          position.x = randFloat(-2, 2);
        });

        if (isFirstTimeRef.current) {
          isFirstTimeRef.current = false;
          isHidingRef.current = false;
        } else {
          onFinish?.();

          if (loop) {
            isHidingRef.current = false;
          } else {
            points.visible = false;
          }
        }
      } else if (stepRef.current === 1) {
        if (fadeOut) {
          // out
          originPositions.forEach((position, i) => {
            position.copy(shapePosition[i]);

            //position.x += randFloat(-2, 2);
            //position.y += randFloat(-1, 1);
            position.z += randInt(0, 2) ? randFloat(0, 6) : randFloat(-6, 6);
          });

          isHidingRef.current = true;
        }
      }

      if (!isHidingRef.current) {
        stepRef.current += 0.2 * delta;
        stepRef.current = Math.min(1, stepRef.current);
      } else {
        stepRef.current -= 0.2 * delta;
        stepRef.current = Math.max(0, stepRef.current);
      }

      const step = stepRef.current;
      const isHiding = isHidingRef.current;

      const material = points.material;
      const positionsBuffer = points.geometry.attributes.position;
      const uniforms = material.uniforms;

      // limt as max opacity the `opacity` prop
      uniforms.opacity.value = opacity * step;

      const deltaStep = isHiding
        ? easings.easeOutQuart(step)
        : easings.easeOutSine(step);

      for (let i = 0; i < positionsBuffer.count; i++) {
        const index = i * 3;

        _VECTOR.copy(originPositions[i]);
        _VECTOR.lerp(shapePosition[i], deltaStep);

        positionsBuffer.array[index] = _VECTOR.x;
        positionsBuffer.array[index + 1] = _VECTOR.y;
        positionsBuffer.array[index + 2] = _VECTOR.z;
      }

      positionsBuffer.needsUpdate = true;
    });

    if (!geometry) return null;

    return (
      <points
        ref={pointsRef}
        position={position}
        rotation={rotation}
        geometry={DOTS_GEOMETRY}
      >
        <shaderMaterial
          uniforms={{
            color: { value: color },
            pointTexture: { value: dotTexture },
            opacity: { value: 0.0 },
          }}
          vertexShader={vertexShader}
          fragmentShader={fragmentShader}
          blending={blending}
          transparent={true}
          depthWrite={false}
          depthTest={true}
        />
      </points>
    );
  }
);

CloudPointsLogo.displayName = "CloudPointsLogo";

const vertexShader = /* glsl */ `
attribute float size;

uniform vec3 color;
uniform float opacity;

varying vec3 vColor;
varying float vOpacity;


void main() {
    vColor = color;
    vOpacity = opacity;

    vec4 newPosition = modelViewMatrix * vec4( position, 1.0 );

    gl_PointSize = size * (projectionMatrix[2][3] * newPosition.w + projectionMatrix[3][3]) / -newPosition.w;

    gl_Position = projectionMatrix * newPosition;
}
`;

const fragmentShader = /* glsl */ `
uniform vec3 color;
uniform sampler2D pointTexture;

varying vec3 vColor;
varying float vOpacity;

void main() {
    gl_FragColor = vec4( vColor, vOpacity );
    gl_FragColor = gl_FragColor * texture2D( pointTexture, gl_PointCoord );
}
`;
