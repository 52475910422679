import AnimateFadeInUp from "./AnimateFadeInUp";
import React, { useState, useEffect } from "react";
import BackToTop from "../app/BackToTop";
import { APP_LINK } from "../constants/all";

const MarketBased = () => {
  const isMobile = window.innerWidth <= 768; // Change the breakpoint as needed
  const boxData = [
    {
      number: "01.",
      title: "Best Pricing",
      description:
        "Best price for all assets on Elys irrespective of the amount of liquidity on the dex",
    },
    {
      number: "02.",
      title: "Increased Volume",
      description:
        "Attracts more users for increased spot and margin trading volume",
    },
    {
      number: "03.",
      title: "System Rewards",
      description: "Increases rewards via swap fees and margin interest fees",
    },
    {
      number: "04.",
      title: "IMPROVED LIQUIDITY",
      description:
        "Enhances non-inflationary ROI and attracts further liquidity",
    },
  ];

  const [activeBoxIndex, setActiveBoxIndex] = useState(0);
  const [hoveredColumnIndex, setHoveredColumnIndex] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (hoveredColumnIndex === null) {
        setActiveBoxIndex((prevIndex) => (prevIndex + 1) % boxData.length);
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [boxData.length, hoveredColumnIndex]);

  return (
    <section className="market-based light-bg">
      <div className="container">
        <div className="market-based-header">
          <AnimateFadeInUp>
            <h2>Oracle-Based Arbitrage Free Pricing</h2>
            <p className="mt-3 mb-4 text-black pb-2">
              Efficiency in decentralized finance is here
            </p>
          </AnimateFadeInUp>
          <AnimateFadeInUp>
            <a
              className="btn btn-secondary"
              href={APP_LINK}
              target="_blank"
              rel="noreferrer"
            >
              Launch App{" "}
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 15L12.5 10L7.5 5"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="square"
                />
              </svg>
            </a>
          </AnimateFadeInUp>
        </div>
        {/* <Row className='justify-content-md-center mt-5 m-0 pt-3 market-based-row'>
            <Col md={3} className="mb-4 mb-md-0">
                <AnimateFadeInUp afterFadeClass={"remove-fade-css"}>
                <div className='market-based-box'>
                <div className='market-based-content'>
                    <div className="number">01.</div>
                    <h5>Best Pricing</h5>
                    <p>
                    Best price for all assets on Elys irrespective of the amount of liquidity on the dex
                    </p>
                    </div>
                </div>  
                </AnimateFadeInUp>
                
                </Col>
                <Col md={3} className="mb-4 mb-md-0">
                    <AnimateFadeInUp afterFadeClass={"remove-fade-css"}>
                    <div className='market-based-box'>
                        <div className='market-based-content'>
                            <div className="number">02.</div>
                            <h5>Increased Volume</h5>
                            <p>Attracts more users for increased spot and margin trading volume                        </p>
                        </div>
                    </div> 
                    </AnimateFadeInUp>
                </Col>
                <Col md={3} className="mb-4 mb-md-0">
                    <AnimateFadeInUp afterFadeClass={"remove-fade-css"}>
                    <div className='market-based-box'>
                <div className='market-based-content'>
                    <div className="number">03.</div>
                    <h5>System Rewards</h5>
                    <p>
                    Increases rewards via swap fees and margin interest fees                    </p>
                    </div>
                </div> 
                    </AnimateFadeInUp>
                </Col>
                <Col md={3} className="mb-4 mb-md-0">
                    <AnimateFadeInUp afterFadeClass={"remove-fade-css"}>  
                <div className='market-based-box'>
                <div className='market-based-content'>
                    <div className="number">04.</div>
                    <h5>IMPROVED LIQUIDITY</h5>
                    <p>
                    Enhances non-inflationary ROI and attracts further liquidity                    </p>
                </div> 
                </div>
                </AnimateFadeInUp>
                </Col>
            </Row> */}

        <div className="justify-content-md-center mt-5 m-0 pt-3 market-based-row row">
          {boxData.map((box, index) => (
            <div
              key={index}
              className="mb-4 mb-md-0 col-md-3"
              onMouseEnter={() => {
                if (!isMobile) {
                  setHoveredColumnIndex(index);
                  setActiveBoxIndex(index); // Set active box to the hovered box
                }
              }}
              onMouseLeave={() => {
                if (!isMobile) {
                  setHoveredColumnIndex(null);
                  setActiveBoxIndex(
                    (prevIndex) => (prevIndex + 1) % boxData.length
                  ); // Resume interval-based toggling
                }
              }}
            >
              <AnimateFadeInUp afterFadeClass={"remove-fade-css"}>
                <div
                  className={`market-based-box ${
                    activeBoxIndex === index || hoveredColumnIndex === index
                      ? "active"
                      : ""
                  }`}
                >
                  <div className="market-based-content">
                    <div className="number">{box.number}</div>
                    <h5>{box.title}</h5>
                    <p>{box.description}</p>
                  </div>
                </div>
              </AnimateFadeInUp>
            </div>
          ))}
        </div>
      </div>
      <BackToTop></BackToTop>
    </section>
  );
};

export default MarketBased;
