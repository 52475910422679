import { PerspectiveCamera } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import { useInView } from "react-intersection-observer";

import { AfterEffect } from "../../app/AfterRender";
import { Scene } from "./Scene";
import { getPixelRatio } from "../../utils/3d.utils";
import { Vector3 } from "three";
import { useIsMobile } from "../../hooks/useIsMobile";

const DESKTOP_CAMERA_DISTANCE = new Vector3(0, 0, 6);

const MOBILE_CAMERA_DISTANCE = new Vector3(0, 0, 3);

export const ElysHeroRender = () => {
  const isMobile = useIsMobile();

  const { ref, inView } = useInView({
    threshold: 0.3,
  });

  return (
    <AfterEffect key={isMobile}>
      <Canvas
        ref={ref}
        gl={{
          powerPreference: "high-performance",
          //antialias: false,
          //stencil: false,
          //depth: false,
        }}
        className="flex flex-1 bg-[#0f0f0f]"
        dpr={getPixelRatio()}
      >
        <PerspectiveCamera
          makeDefault
          position={isMobile ? MOBILE_CAMERA_DISTANCE : DESKTOP_CAMERA_DISTANCE}
        />

        {/* {process.env.NODE_ENV === "development" && (
          <OrbitControls makeDefault />
        )} */}

        {/* <Stats className="absolute bottom-[0px] left-[auto!important] right-[0px] top-[auto!important]" /> */}

        {/* <EffectComposer disableNormalPass multisampling={0}>
          <SMAA />
          <Bloom luminanceThreshold={0} luminanceSmoothing={0.9} height={300} />
        </EffectComposer> */}

        <Scene isMobile={isMobile} />

        {!inView && <DisableRender />}
      </Canvas>

      {/* <div className="fixed right-[20px] top-[90px] flex">
        <Leva fill collapsed />
      </div> */}
    </AfterEffect>
  );
};

const DisableRender = () => {
  useFrame(() => null, 1000);
};
