import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import AnimateFadeInUp from "./AnimateFadeInUp";
import BackToTop from "../app/BackToTop";
import { LottoIcon } from "./lottieIcon";

const Sustainable = () => {
  return (
    <section className="sustainable light-bg position-relative">
      <div className="container">
        <div className="market-based-header">
          <AnimateFadeInUp>
            <h2>Sustainable Defi</h2>
            <p className="mt-3 mb-4 text-black pb-2">
              Multiple rewards sources create non-inflationary yield, shared by
              stakers and liquidity providers.
            </p>
          </AnimateFadeInUp>
        </div>
        <Row className="justify-content-md-center market-based-row">
          <Col lg={12}>
            <AnimateFadeInUp>
              <Image
                src="../sustainable.png"
                className="w-100"
                alt="Sustainable Defi"
              ></Image>
              <div className="sustainable-ani">
                <LottoIcon type={"sustainable"} />
              </div>
            </AnimateFadeInUp>
            {/* <AnimateFadeInUp>

                   
                    <div className='fee-sec'>
                        <div className=''>
                            <div className='fee-sec-box bridging'>
                                <Image src="./sustainable/bridging-fee.svg" className='w-100 img'></Image>
                            </div>
                            <p>
                            STYX 
Bridging Fees
                            </p>
                        </div>

                        <div className=''>
                            <div className='fee-sec-box'>
                                <Image src="./sustainable/swap-fee.svg" className='w-100 img'></Image>
                            </div>
                            <p>
                            Swap Fees
                            </p>
                        </div>

                        <div className=''>
                            <div className='fee-sec-box'>
                                <Image src="./sustainable/tx-fee.svg" className='w-100'></Image>
                            </div>
                            <p>
                            TX Fees
                            </p>
                        </div>

                        <div className=''>
                            <div className='fee-sec-box'>
                                <Image src="./sustainable/margin-fee.svg" className='w-100 img'></Image>
                            </div>
                            <p>
                            Margin Interest Fees
                            </p>
                        </div>

                        <div className=''>
                            <div className='fee-sec-box'>
                                <Image src="./sustainable/mev.svg" className='w-100 img'></Image>
                            </div>
                            <p>
                            MEV
                            </p>
                        </div>
                    </div>
                    <div className='row align-items-center justify-content-center'>
                        <div className='col-auto '>
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="121" viewBox="0 0 6 121" fill="none">
  <path d="M3.5 5L5.88675 1.26184e-07L0.113249 -1.26184e-07L2.5 5L3.5 5ZM2.99999 121L5.88675 116L0.113244 116L2.99999 121ZM2.5 4.5L2.49999 116.5L3.49999 116.5L3.5 4.5L2.5 4.5Z" fill="black"/>
</svg>
                        </div>
                    </div>

                    
                    <div className='row align-items-center justify-content-center'>
                        <div className='col-12 px-lg-5 ms-n5 position-relative'>
                            <Image src="../sustainable/non-yield.svg" className='w-100 pe-0 pe-md-5'>

                            </Image>
                        </div>
                    </div>
                    </AnimateFadeInUp> */}
          </Col>
        </Row>
      </div>
      <BackToTop></BackToTop>
    </section>
  );
};

export default Sustainable;
