import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import {
  APP_LINK,
  BLOG_LINK,
  BRAND_ASSETS_LINK,
  CONTACT_EMAIL,
  DISCORD_LINK,
  DOCS_LINK,
  GITHUB_LINK,
  GOVERNANCE_LINK,
  LINKTHREE_LINK,
  TELEGRAM_LINK,
  TWITTER_LINK,
  WAITLIST_LINK,
  YOUTUBE_LINK,
} from "../constants/all";

function NavScrollExample() {
  return (
    <Navbar
      expand="lg"
      className="bg-body-tertiary py-3"
      bg="dark"
      data-bs-theme="dark"
    >
      <Container>
        <Navbar.Brand href="#">
          <img alt="Logo" src="../logo.svg" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            // style={{ maxHeight: '100px' }}
          >
            <NavDropdown title="Explore" id="navbarExplore">
              <div className="blur">
                <NavDropdown.Item href="#mission">Mission</NavDropdown.Item>
                {/* <NavDropdown.Item href="/404" target="_blank">
                  Careers
                </NavDropdown.Item> */}
                <NavDropdown.Item href={BLOG_LINK} target="_blank">
                  Blog
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="#footer">FAQ’s</NavDropdown.Item> */}
                <NavDropdown.Item href={BRAND_ASSETS_LINK} target="_blank">
                  Brand Assets
                </NavDropdown.Item>
                <NavDropdown.Item
                  href={`mailto:${CONTACT_EMAIL}`}
                  target="_blank"
                >
                  Contact
                </NavDropdown.Item>
              </div>
            </NavDropdown>
            <NavDropdown title="Governance" id="navbarGovernance">
              <div className="blur">
                <NavDropdown.Item href={GOVERNANCE_LINK} target="_blank">
                  Dashboard
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="/404" target="_blank">
                  Commonwealth
                </NavDropdown.Item> */}
              </div>
            </NavDropdown>
            <NavDropdown title="Documentation" id="navbarScrollingDropdown">
              <div className="blur">
                <NavDropdown.Item href={DOCS_LINK} target="_blank">
                  User Docs
                </NavDropdown.Item>
                <NavDropdown.Item href={GITHUB_LINK} target="_blank">
                  Github
                </NavDropdown.Item>
              </div>
            </NavDropdown>

            <NavDropdown title="Community" id="navbarCommunity">
              <div className="blur">
                <NavDropdown.Item href={LINKTHREE_LINK} target="_blank">
                  Linktree
                </NavDropdown.Item>
                <NavDropdown.Item href={DISCORD_LINK} target="_blank">
                  Discord
                </NavDropdown.Item>
                <NavDropdown.Item href={TWITTER_LINK} target="_blank">
                  Twitter
                </NavDropdown.Item>
                <NavDropdown.Item href={TELEGRAM_LINK} target="_blank">
                  Telegram
                </NavDropdown.Item>
                <NavDropdown.Item href={YOUTUBE_LINK} target="_blank">
                  Youtube
                </NavDropdown.Item>
                {/* <NavDropdown.Item href="#action5" target="_blank">Reddit</NavDropdown.Item> */}
              </div>
            </NavDropdown>
          </Nav>
          {/*<Form className="d-flex search-bar me-0  me-md-3 mb-3 mb-md-0">
            <div className='search-bar-box'>
            <Form.Control
              type="search"
              placeholder="Search"
              className=""
              aria-label="Search"
            />
            <img src="../search.svg" className='search-icon'></img>
            </div> 
          </Form>*/}
          <div className="row-md d-md-flex gap-2">
            <Button
              variant="secondary"
              className="mt-3 mt-md-0"
              style={{
                height: "44px",
              }}
              href={WAITLIST_LINK}
              target="_blank"
            >
              Mainnet Waitlist
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 15L12.5 10L7.5 5"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="square"
                />
              </svg>
            </Button>
            <Button
              variant="primary"
              className="mt-3 mt-md-0"
              href={APP_LINK}
              target="_blank"
            >
              Launch Testnet App
            </Button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavScrollExample;
