import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import AnimateFadeInUp from "./AnimateFadeInUp";
import BackToTop from "../app/BackToTop";

const BringingWorld = () => {
  return (
    <section className="bringing-world position-relative">
      <div className="container">
        <div className="bringing-h-content">
          <AnimateFadeInUp>
            <h2 className="text-white">Bringing Worlds Together</h2>
            <p className="mt-3 mb-4">
              Connecting the Cosmos ecosystem via Inter-Blockchain Communication
              (IBC) and other major blockchains via the native EVM Bridge
            </p>
          </AnimateFadeInUp>
        </div>
        <Row className="justify-content-md-center mt-5">
          <Col md={5} className="mb-4 order-md-2">
            <Image
              src="bringing-world-1.svg"
              className="w-100"
              alt="Connecting traders and 
liquidity providers"
            ></Image>
          </Col>
          <Col
            md={5}
            className="text-center bringing-world-box pe-lg-5 mb-4 order-md-1"
          >
            <div>
              <AnimateFadeInUp>
                <h4>Connecting traders and liquidity providers</h4>
              </AnimateFadeInUp>
              <AnimateFadeInUp>
                <p>
                  Empowering users to invest, trade and speculate using the
                  interoperable, peer to peer digital asset platform
                </p>
              </AnimateFadeInUp>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col md={5} className="mb-4">
            <Image
              src="bringing-world-2.svg"
              className="w-100"
              alt="Connecting traders and 
liquidity providers"
            ></Image>
          </Col>
          <Col md={5} className="text-center bringing-world-box ps-lg-5 mb-4">
            <div>
              <AnimateFadeInUp>
                <h4>Connecting TradFi to DeFi</h4>
              </AnimateFadeInUp>
              <AnimateFadeInUp>
                <p>
                  Simplifying the user experience and providing educational
                  resources to onboard web2 users
                </p>
              </AnimateFadeInUp>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={5} className="mb-4 order-md-2">
            <Image
              src="bringing-world-3.svg"
              className="w-100"
              alt="Connecting traders and 
liquidity providers"
            ></Image>
          </Col>
          <Col
            md={5}
            className="text-center bringing-world-box pe-lg-5 mb-4 order-md-1"
          >
            <div>
              <AnimateFadeInUp>
                <h4>Bringing the EVM world to the IBC world</h4>
              </AnimateFadeInUp>
              <AnimateFadeInUp>
                <p>
                  The native EVM Bridge will securely and seamlessly connect
                  external major blockchain assets to the Elys Network DEX
                </p>
              </AnimateFadeInUp>
            </div>
          </Col>
        </Row>
      </div>
      <BackToTop></BackToTop>
    </section>
  );
};

export default BringingWorld;
