import { memo, useRef } from "react";

import { easings, useSpring } from "@react-spring/three";
import { EnvironmentMap, Float, useTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { DoubleSide, EquirectangularReflectionMapping, Vector3 } from "three";
import { degToRad } from "three/src/math/MathUtils.js";

const LOGO_SIZE = 3;

const LOGO_WIDTH = 0.7104 * LOGO_SIZE;

const LOGO_COLOR = "#FFFFFF";

const POSITION = new Vector3(1.2, 0, 0);

const ROTATION = [0, degToRad(-15), 0];

const SIZE = 0.7;

const LOGO_TEXTURE = "/animations/logos/logo-gradient.png";

export const Logo = memo(({ render, ...props }) => {
  const logoRef = useRef(null);

  const { size = SIZE, logoTextureUrl = LOGO_TEXTURE } = props;

  /* const texture = useLoader(RGBELoader, '/hdr/neon.hdr');
  texture.mapping = EquirectangularReflectionMapping; */

  const { envTexture, logoTexture } = useTexture({
    logoTexture: logoTextureUrl,
    envTexture: "/animations/misc/env5.jpg",
  });

  envTexture.mapping = EquirectangularReflectionMapping;

  return (
    <>
      <EnvironmentMap map={envTexture} resolution={1024} />

      <Float
        speed={1}
        rotationIntensity={0.5} // XYZ rotation intensity, defaults to 1
        floatIntensity={0.4} // Up/down float intensity
        floatingRange={[-0.2, 0.2]}
      >
        <mesh ref={logoRef} position={[0, 0, -150]}>
          <planeGeometry args={[LOGO_WIDTH * size, LOGO_SIZE * size]} />

          <meshStandardMaterial
            map={logoTexture}
            color={LOGO_COLOR}
            side={DoubleSide}
            transparent
            roughness={0}
            metalness={1}
            colorWrite={render}
          />
        </mesh>
      </Float>

      {render && <Render {...props} logoRef={logoRef} />}
    </>
  );
});

Logo.displayName = "Logo";

const Render = ({ position = POSITION, onFinish, logoRef }) => {
  const [props] = useSpring(
    () => ({
      from: {
        position: [position.x + 60, position.y, position.z - 150],
        rotation: [0, Math.PI, Math.PI],
      },
      to: {
        position: [position.x, position.y, position.z],
        rotation: ROTATION,
      },

      loop: false,

      config: {
        duration: 3000,

        easing: easings.easeOutQuart,
      },
    }),
    []
  );

  const [props1] = useSpring(
    () => ({
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },

      loop: false,

      config: {
        duration: 2000,

        easing: easings.easeOutQuad,
      },

      onRest: () => {
        onFinish?.();
      },
    }),
    []
  );

  const [props2] = useSpring(
    () => ({
      from: {
        metalness: 2,
        roughness: -4,
      },
      to: {
        metalness: 0,
        roughness: 2,
      },

      loop: false,

      config: {
        duration: 8000,

        easing: easings.easeOutQuad,
      },
    }),
    []
  );

  useFrame(() => {
    const logo = logoRef.current;
    if (!logo) return;

    const material = logo.material;

    material.opacity = props1.opacity.get();

    material.roughness = props2.roughness.get();
    material.metalness = props2.metalness.get();

    logo.position.set(...props.position.get());
    logo.rotation.set(...props.rotation.get());
  });

  return null;
};
