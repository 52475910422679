import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import AnimateFadeInUp from "./AnimateFadeInUp";
import BackToTop from "../app/BackToTop";

const UltimateExperience = () => {
  return (
    <section className="ultimate-experience light-bg">
      <div className="container">
        <div className="content-sec">
          <div className="text-center mb-3 mb-md-5 pb-3">
            <AnimateFadeInUp>
              <h2>Built For The Ultimate User Experience</h2>
              <p>Ushering in a new era of DeFi</p>
            </AnimateFadeInUp>
          </div>
          <Row className="justify-content-md-center">
            <Col md={5} className="mb-4">
              <AnimateFadeInUp>
                <div className="ultimate-box">
                  <div className="text-center mt-5">
                    <h4 className="pt-3">USDC-Paired Pools</h4>
                    <p className="mt-3 px-5 mx-3">
                      User adds liquidity directly to a dedicated pool, gaining
                      exposure to that specific asset as well as USDC
                    </p>
                  </div>
                  <div className="mb-4 paired-bg">
                    <Image
                      src="paired-img.svg"
                      className="w-100 d-none d-md-block"
                      alt="USDC-Paired Pools Image"
                    ></Image>
                    <Image
                      src="paired-img.png"
                      className="w-100 d-block d-md-none"
                      alt="USDC-Paired Pools Image"
                    ></Image>
                  </div>
                </div>
              </AnimateFadeInUp>
            </Col>
            <Col md={5} className="mb-4">
              <AnimateFadeInUp>
                <div className="ultimate-box">
                  <div className="molecule-bg">
                    <Image
                      src="molecule-img.svg"
                      className="w-100 z-1 position-relative"
                      alt="Molecule Image"
                    ></Image>
                  </div>
                  <div className="text-center position-relative">
                    <h4 className="">Molecule Pools</h4>
                    <p className="mt-3 px-5 mx-3 mb-4">
                      User adds liquidity to an ETF style index pool, gaining
                      exposure to multiple assets simultaneously as well as USDC
                    </p>
                  </div>
                </div>
              </AnimateFadeInUp>
            </Col>

            <Col md={5} className="mb-4">
              <AnimateFadeInUp>
                <div className="ultimate-box">
                  <div className="text-center mt-5">
                    <h4 className="pt-3">Cutting edge integrations</h4>
                    <p className="mt-3 px-5 mx-3">
                      New oracle-based dynamic-weighted pools resolve fragmented
                      liquidity and reduce slippage. Real-time pricing data from
                      at least three independent sources for fail-safe
                      reliability.
                    </p>
                  </div>
                  <div className="mb-0 cutting-bg">
                    <Image
                      src="cutting-img.svg"
                      className="w-100"
                      alt="Paired Pools Image"
                    ></Image>
                  </div>
                </div>
              </AnimateFadeInUp>
            </Col>
            <Col md={5} className="mb-4">
              <AnimateFadeInUp>
                <div className="ultimate-box">
                  <div className="molecule-bg">
                    <Image
                      src="native-margin-img.svg"
                      className="w-100"
                      alt="Perpetual trading trading capabilities"
                    ></Image>
                  </div>
                  <div className="text-center position-relative">
                    <h4 className="pt-4">Perpetual trading capabilities</h4>
                    <p className="mt-3 px-5 mx-3 mb-4">
                      Allows users to borrow funds in order to enter trade
                      positions that are larger than their initial collateral.
                    </p>
                  </div>
                </div>
              </AnimateFadeInUp>
            </Col>
          </Row>
        </div>
      </div>
      <BackToTop></BackToTop>
    </section>
  );
};

export default UltimateExperience;
